if ('define' in window) {
define("discourse/theme-2/discourse/initializers/discourse-custom-header-links", ["exports", "discourse/lib/api", "../components/custom-header-links"], function (_exports, _api, _customHeaderLinks) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(2);
  const themePrefix = key => `theme_translations.2.${key}`;
  var _default = _exports.default = (0, _api.apiInitializer)("1.14.0", api => {
    api.renderInOutlet("before-header-panel", _customHeaderLinks.default);
  });
});
}
