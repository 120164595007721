if ('define' in window) {
define("discourse/theme-2/discourse/components/custom-header-links", ["exports", "@ember/component", "@glimmer/component", "@ember/string", "@ember/template-factory"], function (_exports, _component, _component2, _string, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const settings = require("discourse/lib/theme-settings-store").getObjectForTheme(2);
  const themePrefix = key => `theme_translations.2.${key}`;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShow}}
    {{!
      @outletArgs.attrs.topic is the legacy version. Can be removed once core v3.2.0.beta4 is released
      and this Theme's compatibility file is updated
    }}
    <ul
      class="custom-header-links
        {{if
          (or @outletArgs.topic @outletArgs.attrs.topic)
          'custom-header-links--hide-links'
        }}"
    >
      {{#each this.links as |link|}}
        <li
          class={{concat-class
            "headerLink"
            link.device
            link.locale
            link.linkClass
            link.hideOnScroll
          }}
        >
          <a
            title={{link.anchorAttributes.title}}
            href={{link.anchorAttributes.href}}
            target={{link.anchorAttributes.target}}
          >
            {{link.linkText}}
          </a>
        </li>
      {{/each}}
    </ul>
  {{/if}}
  */
  {
    "id": null,
    "block": "[[[41,[30,0,[\"shouldShow\"]],[[[1,\"  \"],[10,\"ul\"],[15,0,[29,[\"custom-header-links\\n      \",[52,[28,[37,1],[[30,1,[\"topic\"]],[30,1,[\"attrs\",\"topic\"]]],null],\"custom-header-links--hide-links\"]]]],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"links\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[15,0,[28,[37,4],[\"headerLink\",[30,2,[\"device\"]],[30,2,[\"locale\"]],[30,2,[\"linkClass\"]],[30,2,[\"hideOnScroll\"]]],null]],[12],[1,\"\\n        \"],[10,3],[15,\"title\",[30,2,[\"anchorAttributes\",\"title\"]]],[15,6,[30,2,[\"anchorAttributes\",\"href\"]]],[15,\"target\",[30,2,[\"anchorAttributes\",\"target\"]]],[12],[1,\"\\n          \"],[1,[30,2,[\"linkText\"]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[2]],null],[1,\"  \"],[13],[1,\"\\n\"]],[]],null]],[\"@outletArgs\",\"link\"],false,[\"if\",\"or\",\"each\",\"-track-array\",\"concat-class\"]]",
    "moduleName": "discourse/components/custom-header-links.hbs",
    "isStrictMode": false
  });
  class CustomHeaderLinks extends _component2.default {
    get shouldShow() {
      return settings.custom_header_links?.length > 0;
    }
    get links() {
      return settings.custom_header_links.reduce((result, link) => {
        const linkText = link.text;
        const linkTitle = link.title;
        const linkHref = link.url;
        const target = link.target;
        const hideOnScroll = link.hide_on_scroll;
        const locale = link.locale;
        const device = link.view;
        if (!linkText || locale && document.documentElement.lang !== locale) {
          return result;
        }
        const linkClass = `${(0, _string.dasherize)(linkText)}-custom-header-links`; // legacy name

        const anchorAttributes = {
          title: linkTitle,
          href: linkHref,
          target: target === "self" ? "" : "_blank"
        };
        result.push({
          device: `headerLink--${device}`,
          hideOnScroll: `headerLink--${hideOnScroll}`,
          locale: locale ? `headerLink--${locale}` : null,
          linkClass,
          anchorAttributes,
          linkText
        });
        return result;
      }, []);
    }
  }
  _exports.default = CustomHeaderLinks;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, CustomHeaderLinks);
});
}
